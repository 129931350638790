import * as React from 'react';
import { noop } from '../../../utils';

export interface ICoverageBoxContext {
    expandedSectionId: string | null;
    expandSection: (sectionId: string | null) => void;
}

/**
 * @deprecated Use `ListBoxContext`
 */

export const CoverageBoxContext = React.createContext<ICoverageBoxContext>({
    expandSection: noop,
    expandedSectionId: null,
});
