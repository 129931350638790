import * as React from 'react';
import { Button, ButtonProps } from './button';

/**
 * `CoverageButton` is a wrapper around `Button` component.
 *  It provides better default for Button element used in application related to coverage
 *  If you need to tweak the style that should applies for your application, refer to source code and implement your version.
 *  @deprecated use Button component with minWidth='wide' instead
 */
export const CoverageButton = React.forwardRef<HTMLButtonElement, ButtonProps>(function CoverageButtonComp({ minWidth = 'wide', ...buttonProps }, ref) {
    return <Button minWidth={minWidth} {...buttonProps} ref={ref} />;
});
