// @overview: custom hooks
import observeRect from '@reach/observe-rect';
import { MutableRefObject, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { preloadImage } from './dom';
import { getId } from './index';

/**
 * A hook to returns an id if your component need it but you doesn't want to make it as mandatory props
 *
 * This hook is preferred than using `getId` in your React component as it will lazily get the default id only when it is needed.
 * @param providedId id provided to your component
 * @param idPrefix prefix of the id, default to `"@flos/react-ui-"`
 */
export function useId(providedId: string | undefined, idPrefix?: string): string {
    const generatedIdRef = useRef<string | null>(null);
    if (providedId) {
        return providedId;
    }

    if (!generatedIdRef.current) {
        generatedIdRef.current = getId(idPrefix);
    }

    return generatedIdRef.current;
}

/**
 *
 * @param imageSrc url for the image that you want to load
 * @param loadEagerly whether the image preloading once the component use this hook is mounted. If you want to preload manually, use the returned callback. Default to `true`.
 * @returns the function to start loading the image. Only applicable if you set `loadEagerly` to `false`.
 */
export function usePreloadImage(imageSrc: string, loadEagerly = true) {
    const [shouldPreload, setShouldPreload] = useState(loadEagerly);
    const started = useRef(false);

    useEffect(() => {
        if (imageSrc && shouldPreload && !started.current) {
            preloadImage(imageSrc);
            started.current = true;
        }
    }, [imageSrc, shouldPreload]);

    if (loadEagerly) {
        return;
    }
    return function startLoad() {
        setShouldPreload(true);
    };
}

/**
 * A copy-paste from `@reach/rect` and modification because that package assume `nodeRef.current` never change and use its initial value.
 * For our use case, because web component are lazy loaded, that assumption doesn't work for us.
 * @param nodeRef
 * @param observe
 * @private
 */
export function useRect(nodeRef: RefObject<HTMLElement>, observe = true) {
    const [rect, setRect] = useState<DOMRect | null>(null);
    const observerRef = useRef<any | null>(null);

    useLayoutEffect(() => {
        if (!nodeRef.current) {
            return;
        }

        if (!observerRef.current) {
            observerRef.current = observeRect(nodeRef.current, setRect);
        }

        if (observe) {
            observerRef.current.observe();
        }

        return () => observerRef.current.unobserve();
    }, [observe, nodeRef]);

    return rect;
}

/**
 * A hook to set a state temporarily and restore after some wait time.
 *
 * See example [here](#copy-transient-demo).
 *
 * @param steadyState the value that you want to be restored to everytime the value is changed to other value
 * @param restorationTime wait time before restore to steadyState
 */
export const useTransientState = <T>(steadyState: T, restorationTime = 1000) => {
    const [state, setState] = useState<T>(steadyState);
    const [calledTimes, setCallTimes] = useState(0);

    const setTemporaryState = useCallback(function setTemporaryStateImpl(newValue: React.SetStateAction<T>) {
        setState(newValue);
        setCallTimes((t) => t + 1);
    }, []);

    useEffect(() => {
        if (state !== steadyState && restorationTime) {
            const timeoutId = setTimeout(() => setState(steadyState), restorationTime);

            return () => clearTimeout(timeoutId);
        }
        return;
    }, [state, steadyState, restorationTime, calledTimes]);

    return [state, setTemporaryState] as const;
};

/**
 * Get the latest value of a particular variable to bypass closure.
 * @param value
 */
export const useLatest = <T>(value: T) => {
    const valueRef = useRef(value);
    valueRef.current = value;
    return valueRef;
};

/**
 * Use callback to set `ref`. Useful if you want to keep initializing your ref.
 * @param valueGetter
 */
export const useLazyRef = <T>(valueGetter: () => T) => {
    const ref = useRef<T | null>(null);
    if (!ref.current) {
        ref.current = valueGetter();
    }
    return ref as MutableRefObject<T>;
};
