import * as React from 'react';
import { pickNonPrimitive, pickPrimitive } from '../../utils/object';
import { isFunction } from '../../utils/typeguard';

export interface IFlosWrapperProps {
    /**
     * tag for the web component, e.g. "flos-date-field"
     */
    tag: string;
    /**
     * an object for all event listener for the underlying web component
     */
    eventHandlers?: {
        [eventType: string]: (event: any) => void;
    };
    /**
     * ref to obtain instance of the underlying web component
     */
    innerRef?: React.Ref<any>;
    [key: string]: any;
}

/**
 * `FlosWrapper` is just a simple wrapper to render custom web Components
 *
 * - It is assumed the List of events in `eventHandlers` does not change after initial render.
 * - Props with primitive type (string, boolean, number) will be pass down as attributes
 * - Non-primitive (Object, fn, others) will be set as properties. It is assumed non-primitive will not change after initial render
 */
export class FlosWrapper extends React.Component<IFlosWrapperProps> {
    componentRef = React.createRef<HTMLElement>();
    // we keep track of List of event type based on initial render
    // the List of callback stored here actually is not the callback provided during initial render, but a curried callback from `handleEvent`
    eventHandlers: {
        [eventType: string]: (event: any) => void;
    } = {};

    handleEvent = (eventType: string) => (event: any) => {
        // we get the eventHandler **at the moment** this function is called
        if (this.props.eventHandlers) {
            const eventHandler = this.props.eventHandlers[eventType];

            if (eventHandler) {
                eventHandler(event);
            }
        }
    };

    componentDidMount() {
        const { tag, eventHandlers, children, innerRef, ...restProps } = this.props;
        const componentRef = this.componentRef.current;
        if (componentRef) {
            if (eventHandlers) {
                Object.keys(eventHandlers).forEach((eventType) => {
                    this.eventHandlers[eventType] = this.handleEvent(eventType);
                    componentRef.addEventListener(eventType, this.eventHandlers[eventType]);
                });
            }

            Object.keys(pickNonPrimitive(restProps)).forEach((key) => {
                componentRef[key] = restProps[key];
            });
        }
    }

    componentWillUnmount() {
        const { eventHandlers } = this.props;
        if (eventHandlers && this.componentRef.current) {
            Object.keys(this.eventHandlers).forEach((eventType) => {
                (this.componentRef.current as HTMLElement).removeEventListener(eventType, this.eventHandlers[eventType]);
            });
        }
    }

    render() {
        const { tag: Component, eventHandlers, children, innerRef, ...restProps } = this.props;
        return (
            <Component
                {...(pickPrimitive(restProps) as any)}
                ref={(componentRef: any) => {
                    (this.componentRef as React.MutableRefObject<any>).current = componentRef;
                    if (innerRef) {
                        if (isFunction(innerRef)) {
                            innerRef(componentRef);
                        } else {
                            (innerRef as React.MutableRefObject<any>).current = componentRef;
                        }
                    }
                }}
            >
                {children}
            </Component>
        );
    }
}

export default FlosWrapper;
