import * as React from 'react';
import { omit } from '../../../utils/object';
import { isDefined } from '../../../utils/typeguard';

type HeightType = number | string | null;

const PROPS_TO_OMIT: ReadonlyArray<keyof AnimateHeightProps> = [
    'animateOpacity',
    'applyInlineTransitions',
    'children',
    'contentClassName',
    'delay',
    'duration',
    'easing',
    'height',
    'onAnimationEnd',
    'onAnimationStart',
    'expanded',
];

// Start animation Helper using nested requestAnimationFrames
const startAnimationHelper = (callback: () => void) => {
    const frameIds: number[] = [];
    frameIds[0] = requestAnimationFrame(() => {
        frameIds[1] = requestAnimationFrame(() => {
            callback();
        });
    });
    return frameIds;
};

function isNumber(n: any): n is number {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function isPercentage(height: any): height is string {
    // Percentage height
    return typeof height === 'string' && height.search('%') === height.length - 1 && isNumber(height.substr(0, height.length - 1));
}

function runCallback<Param>(callback: undefined | ((param: Param) => void), params: Param) {
    if (callback && typeof callback === 'function') {
        callback(params);
    }
}

export interface AnimateHeightProps extends Omit<JSX.IntrinsicElements['div'], 'onAnimationStart' | 'onAnimationEnd'> {
    animateOpacity?: boolean;
    applyInlineTransitions?: boolean;
    children: any;
    contentClassName?: string;
    delay?: number;
    duration?: number;
    easing?: string;
    /**
     * Height of the section. If the height is flexible and you just want to
     * do expand/collapse, use `expanded` props
     */
    height?: string | number;
    /**
     * A alias for `height` props where expanded = true implies
     * height = 'auto', expanded = false implies height = 0
     */
    expanded?: boolean;
    onAnimationEnd?: (data: { newHeight: HeightType }) => void;
    onAnimationStart?: (data: { newHeight: HeightType }) => void;
}

interface IAnimateHeightStates {
    height: number | string;
    overflow: string;
    shouldUseTransitions: boolean;
}

/**
 * This is the animating part of out accordions. It can be used for other things where you need to animate the height of an element.
 * This component is just copy and paste from the package [`react-animate-height`](https://www.npmjs.com/package/react-animate-height).
 * Included in this package to be tree-shaken.
 */
export class AnimateHeight extends React.Component<AnimateHeightProps, IAnimateHeightStates> {
    contentElement: HTMLDivElement | null;
    timeoutID: number | null;
    animationId: number[] | null = null;
    animationClassesTimeoutID: number | null;

    constructor(props: AnimateHeightProps) {
        super(props);

        const heightValue = deriveEffectiveHeight(props);

        let height: number | string = 'auto';
        let overflow = 'visible';

        if (isNumber(heightValue)) {
            height = heightValue < 0 ? 0 : heightValue;
            overflow = 'hidden';
        } else if (isPercentage(heightValue)) {
            height = heightValue;
            overflow = 'hidden';
        }

        this.state = {
            height,
            overflow,
            shouldUseTransitions: false,
        };
    }

    componentDidMount() {
        const { height } = this.state;

        // Hide content if height is 0 (to prevent tabbing into it)
        // Check for contentElement is added cause this would fail in tests (react-test-renderer)
        // Read more here: https://github.com/Stanko/react-animate-height/issues/17
        if (this.contentElement && this.contentElement.style) {
            this.hideContent(height);
        }
    }

    componentDidUpdate(prevProps: AnimateHeightProps, prevState: IAnimateHeightStates) {
        const { delay, duration, onAnimationEnd, onAnimationStart } = this.props;

        const heightVal = deriveEffectiveHeight(this.props);
        const prevHeight = deriveEffectiveHeight(prevProps);

        // Check if 'height' prop has changed
        if (this.contentElement && heightVal !== prevHeight) {
            // Remove display: none from the content div
            // if it was hidden to prevent tabbing into it
            this.showContent(prevState.height);

            // Cache content height
            this.contentElement.style.overflow = 'hidden';
            const contentHeight = this.contentElement.offsetHeight;
            this.contentElement.style.overflow = '';

            // set total animation time
            const totalDuration = (duration || 0) + (delay || 0);

            let newHeight: number | null | string = null;
            const timeoutState: {
                height: HeightType;
                overflow: string | null;
                shouldUseTransitions?: boolean;
                animationStateClasses?: string;
            } = {
                height: null, // it will be always set to either 'auto' or specific number
                overflow: 'hidden',
            };
            const isCurrentHeightAuto = prevState.height === 'auto';

            if (isNumber(heightVal)) {
                // If new height is a number
                newHeight = heightVal < 0 ? 0 : heightVal;
                timeoutState.height = newHeight;
            } else if (isPercentage(heightVal)) {
                newHeight = heightVal;
                timeoutState.height = newHeight;
            } else {
                // If not, animate to content height
                // and then reset to auto
                newHeight = contentHeight; // TODO solve contentHeight = 0
                timeoutState.height = 'auto';
                timeoutState.overflow = null;
            }

            if (isCurrentHeightAuto) {
                // This is the height to be animated to
                timeoutState.height = newHeight;

                // If previous height was 'auto'
                // set starting height explicitly to be able to use transition
                newHeight = contentHeight;
            }

            // Set starting height and animating classes
            // We are safe to call set state as it will not trigger infinite loop
            // because of the "height !== prevProps.height" check
            this.setState({
                height: newHeight,
                overflow: 'hidden',
                // When animating from 'auto' we first need to set fixed height
                // that change should be animated
                shouldUseTransitions: !isCurrentHeightAuto,
            });

            // Clear timeouts
            clearTimeout(this.timeoutID as number);
            clearTimeout(this.animationClassesTimeoutID as number);

            if (isCurrentHeightAuto) {
                // When animating from 'auto' we use a short timeout to start animation
                // after setting fixed height above
                timeoutState.shouldUseTransitions = true;

                this.animationId = startAnimationHelper(() => {
                    this.setState(timeoutState as any);

                    // ANIMATION STARTS, run a callback if it exists
                    runCallback(onAnimationStart, { newHeight: timeoutState.height });
                });

                // Set static classes and remove transitions when animation ends
                this.animationClassesTimeoutID = (setTimeout as Window['setTimeout'])(() => {
                    this.setState({
                        shouldUseTransitions: false,
                    });

                    // ANIMATION ENDS
                    // Hide content if height is 0 (to prevent tabbing into it)
                    this.hideContent(timeoutState.height);
                    // Run a callback if it exists
                    runCallback(onAnimationEnd, { newHeight: timeoutState.height });
                }, totalDuration);
            } else {
                // ANIMATION STARTS, run a callback if it exists
                runCallback(onAnimationStart, { newHeight });

                // Set end height, classes and remove transitions when animation is complete
                this.timeoutID = (setTimeout as Window['setTimeout'])(() => {
                    timeoutState.shouldUseTransitions = false;

                    this.setState(timeoutState as any);

                    // ANIMATION ENDS
                    // If height is auto, don't hide the content
                    // (case when element is empty, therefore height is 0)
                    if (heightVal !== 'auto') {
                        // Hide content if height is 0 (to prevent tabbing into it)
                        this.hideContent(newHeight); // TODO solve newHeight = 0
                    }
                    // Run a callback if it exists
                    runCallback(onAnimationEnd, { newHeight });
                }, totalDuration);
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutID as number);
        clearTimeout(this.animationClassesTimeoutID as number);
        if (this.animationId) {
            this.animationId.forEach(cancelAnimationFrame);
        }
    }

    showContent(height: HeightType) {
        if (height === 0 && this.contentElement) {
            this.contentElement.style.display = '';
        }
    }

    hideContent(newHeight: HeightType) {
        if (newHeight === 0 && this.contentElement) {
            this.contentElement.style.display = 'none';
        }
    }

    render() {
        const { animateOpacity, applyInlineTransitions, children, contentClassName, duration, easing, delay, style } = this.props;
        const { height, overflow, shouldUseTransitions } = this.state;

        const componentStyle: React.CSSProperties = {
            ...style,
            height,
            overflow: overflow || (style && style.overflow),
        };

        if (shouldUseTransitions && applyInlineTransitions) {
            componentStyle.transition = `height ${duration}ms ${easing} ${delay}ms`;

            // Include transition passed through styles
            if (style && style.transition) {
                componentStyle.transition = `${style.transition}, ${componentStyle.transition}`;
            }

            // Add webkit vendor prefix still used by opera, blackberry...
            componentStyle.WebkitTransition = componentStyle.transition;
        }

        const contentStyle: React.CSSProperties = {};

        if (animateOpacity) {
            contentStyle.transition = `opacity ${duration}ms ${easing} ${delay}ms`;
            // Add webkit vendor prefix still used by opera, blackberry...
            contentStyle.WebkitTransition = contentStyle.transition;

            if (height === 0) {
                contentStyle.opacity = 0;
            }
        }

        return (
            <div {...omit(this.props, PROPS_TO_OMIT)} aria-hidden={height === 0} style={componentStyle}>
                <div className={contentClassName} style={contentStyle} ref={(el) => (this.contentElement = el)}>
                    {children}
                </div>
            </div>
        );
    }

    static defaultProps = {
        animateOpacity: false,
        applyInlineTransitions: true,
        delay: 0,
        duration: 250,
        easing: 'ease',
        style: {},
    };
}

function deriveEffectiveHeight(props: AnimateHeightProps) {
    return !isDefined(props.height) && isDefined(props.expanded) ? (props.expanded ? 'auto' : 0) : props.height;
}

export default AnimateHeight;
