import cx from 'classnames';
import * as React from 'react';
import { getId } from '../../../utils';
import { isDefined } from '../../../utils/typeguard';
import { CoverageBoxContext, ICoverageBoxContext } from './coverage-box-context';
import { CoverageBoxDropdownSection } from './coverage-box-dropdown-section';
import { CoverageBoxToggleButton } from './coverage-box-toggle-button';

export interface ICoverageBoxProps {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    titleContent?: React.ReactNode;
    /** id to be used for accessibility attributes */
    id?: string;
    /** actions associated with this CoverageBox */
    actions?: React.ReactNode;
    /** variant of the actions */
    actionVariant?: 'add-default' | 'add-grey' | 'link' | 'manual-change' | 'standard';
    /** text next to action */
    actionSubtext?: React.ReactNode;
    /** content of the collapsible section */
    content?: React.ReactNode;
    toggleBtnContent?: React.ReactNode;
    /** content of the toggle Button when it is expanded. Will show `toggleBtnContent` is not specified */
    expandedToggleBtnContent?: React.ReactNode;
    /** state if the details should be expanded by default */
    expandedByDefault?: boolean;
    /** `CoverageBoxDropdownSection` elements for this Box. Use `content` props if you only have one section. */
    dropdownSections?: React.ReactNode;
    /** `CoverageBoxToggleButton` elements for this Box. Use `toggleBtnContent` and/or `expandedToggleBtnContent` props if you only have one section */
    toggleButtons?: React.ReactNode;
    /** id for the section that is expanded initially. Use `expandedByDefault` if you only have one section. */
    defaultExpandedSectionId?: string;
    /** `<CoverageBoxPills />` to be shown. If you have multiple pills, wrap them with `React.Fragment` */
    pills?: React.ReactNode;
    /** if the box should be disbaled */
    isDisabled?: boolean;
    /** text if extra banner below box  */
    bannerText?: string;
}

/**
 * `CoverageBox` is a component that allows you to show single/multiple collapsible sections.
 * - For single collapsible section, use `content`, `toggleBtnContent`, `expandedToggleBtnContent`, and `expandedByDefault` props
 * - For multiple collapsible sections, use `dropdownSections` (with `CoverageBoxDropdownSection`), `toggleButtons` (with `CoverageBoxToggleButton`), and `defaultExpandedSectionId` props.
 * @deprecated Use `ListBox` component
 */
export class CoverageBox extends React.Component<ICoverageBoxProps, ICoverageBoxContext> {
    id = this.props.id || getId();

    expandSection = (sectionId: string | null) =>
        this.setState({
            expandedSectionId: sectionId,
        });

    state = {
        expandSection: this.expandSection,
        expandedSectionId: this.props.defaultExpandedSectionId || (this.props.expandedByDefault ? `${this.id}-details` : null),
    };

    render() {
        const { ...props } = this.props;
        const isSingleCollapsible = isDefined(props.content);

        return (
            <CoverageBoxContext.Provider value={this.state}>
                <div
                    id={this.id}
                    className={cx('flos-ResponsiveCoverage Box', props.isDisabled && 'flos-ResponsiveCoverage--uncovered', this.state.expandedSectionId !== null && 'expanded')}
                >
                    <div className="flos-ResponsiveCoverage-header">
                        <div className="flos-ResponsiveCoverage-wrapper">
                            <div className={'flos-ResponsiveCoverage-titlegroup'}>
                                {props.title && <h3 id={`${this.id}-header`}>{props.title}</h3>}
                                {props.subtitle && <p>{props.subtitle}</p>}
                                {props.titleContent}
                                <ToggleButtonsSection
                                    id={this.id}
                                    isSingleCollapsible={isSingleCollapsible}
                                    toggleBtnContent={props.toggleBtnContent}
                                    expandedToggleBtnContent={props.expandedToggleBtnContent}
                                    toggleButtons={props.toggleButtons}
                                />
                            </div>
                            {props.actions && (
                                <div className="flos-ResponsiveCoverage-action hidden-xs">
                                    <div className={cx('action', props.actionVariant)}>
                                        {props.actions}
                                        {props.actionSubtext && <p className="action-sub-text">{props.actionSubtext}</p>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {isSingleCollapsible && (
                        <CoverageBoxDropdownSection id={`${this.id}-details`} aria-labelledby={`${this.id}-header`}>
                            {props.content}
                        </CoverageBoxDropdownSection>
                    )}
                    {props.dropdownSections}
                    {props.bannerText && (
                        <>
                            <div className="flos-ResponsiveCoverage-attention hidden-xs">{props.bannerText}</div>
                            <div className="flos-ResponsiveCoverage-attention-mobile visible-xs">{props.bannerText}</div>
                        </>
                    )}
                    {props.actions && (
                        <div className="flos-ResponsiveCoverage-action flos-ResponsiveCoverage-action--space-between visible-xs">
                            <div className={cx('action', props.actionVariant)}>
                                {props.actions}
                                {props.actionSubtext && <p className="action-sub-text">{props.actionSubtext}</p>}
                            </div>
                        </div>
                    )}
                    {props.pills && (
                        <div className="Box--adv__body">
                            <section className="Section Section--low">
                                <div className="checked-pills-wrapper">{props.pills}</div>
                            </section>
                        </div>
                    )}
                </div>
            </CoverageBoxContext.Provider>
        );
    }
}

interface IToggleButtonSectionProps {
    isSingleCollapsible: boolean;
    id: string;
}

const ToggleButtonsSection: React.FC<IToggleButtonSectionProps & Pick<ICoverageBoxProps, 'toggleBtnContent' | 'expandedToggleBtnContent' | 'toggleButtons'>> = ({
    isSingleCollapsible,
    id,
    toggleBtnContent,
    expandedToggleBtnContent,
    toggleButtons,
}) => (
    <div className={cx('flos-ResponsiveCoverage-links')}>
        {isSingleCollapsible && <CoverageBoxToggleButton sectionId={`${id}-details`} label={toggleBtnContent} expandedLabel={expandedToggleBtnContent} />}
        {toggleButtons}
    </div>
);
