import classnames from 'classnames';
import * as React from 'react';
import { callAll } from '../../../utils/fp';
import { CoverageBoxContext } from './coverage-box-context';

export interface ICoverageBoxToggleButtonProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    /** id of the section that is controlled by this Button */
    sectionId: string;
    /** content of the Button */
    label: React.ReactNode;
    /** content of the Button when the controlled section is expanded. If not specified the content will use `label` props */
    expandedLabel?: React.ReactNode;
}

/**
 * `CoverageBoxToggleButton` is a component that must be used within a `CoverageBox` component.
 *  Other than `sectionId`, `label`, and `expandedLabel`, it also accepts all props an `<a>` accepts.
 *  @deprecated Use `ListBoxPanelLink` component
 */
export const CoverageBoxToggleButton: React.FC<ICoverageBoxToggleButtonProps> = ({ sectionId, label, expandedLabel, className, onClick, ...props }) => (
    <CoverageBoxContext.Consumer>
        {({ expandSection, expandedSectionId }) => {
            const isExpanded = expandedSectionId === sectionId;
            const displayedLabel = isExpanded ? expandedLabel || label : label;

            return (
                <a
                    href={`#${sectionId}`}
                    className={classnames('link link-details', className)}
                    aria-expanded={isExpanded}
                    onClick={callAll((ev) => {
                        ev.preventDefault();
                        expandSection(isExpanded ? null : sectionId);
                    }, onClick)}
                    {...props}
                >
                    {displayedLabel}
                </a>
            );
        }}
    </CoverageBoxContext.Consumer>
);
