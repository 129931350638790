// @overview: Generic utilities
export function noop() {
    // noop
}

// pseudo-random value
let id = new Date().getMilliseconds();

/**
 * generate an unique id for the Javascript session, this is useful when you need an temporary id that will not be persisted
 * @param prefix prefix to be prepended to the id, default to `@flos/react-ui-`
 */
export const getId = (prefix: string = '@flos/react-ui-') => `${prefix}${id++}`;

/**
 * Replace parts of string with dynamic value
 *
 * Example:
 *
 * ```js
 * import { replaceStr } from '@flos/react-ui';
 *
 * const template = `{firstname} {lastname}`;
 * const values = {
 *   '{firstname}': 'Malcolm',
 *   '{lastname}': 'Kee',
 * };
 *
 * console.log(replaceStr(template, values)); // "Malcolm Kee"
 * ```
 *
 * @param template the template where parts of the string include specific sections to be replaced
 * @param replaceItems an object that contains <string_to_be_replaced>: <final_string>
 */
export const replaceStr = (template: string, replaceItems: { [key: string]: string }) => {
    const regx = new RegExp(Object.keys(replaceItems).join('|'), 'gi');
    template = template.replace(regx, (matched) => {
        return replaceItems[matched];
    });
    return template;
};

/**
 * Create a Promise to resolve later some wait time.
 *
 * @param waitTime wait time in millisecond before the promise is resolve
 */
export const wait = (waitTime: number): Promise<void> => new Promise((fulfill) => setTimeout(fulfill, waitTime));
