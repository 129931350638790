import { useId } from '@reach/auto-id';
import { composeEventHandlers } from '@reach/utils';
import { useEffect, useRef, useState } from 'react';
import { useRect } from './hooks';

////////////////////////////////////////////////////////////////////////////////
// ~The states~

// nothing goin' on
const IDLE = 'idle';

// we're considering showing the tooltip, but we're gonna wait a sec
const FOCUSED = 'focused';

// IT'S ON
const VISIBLE = 'visible';

// Focus has left, but we want to keep it visible for a sec
const LEAVING_VISIBLE = 'leavingVisible';

// The user clicked the tool, so we want to hide the thing, we can't just use
// IDLE because we need to ignore mousemove, etc.
const DISMISSED = 'dismissed';

const chart = {
    initial: IDLE,
    states: {
        [IDLE]: {
            enter: clearContextId,
            on: {
                focus: VISIBLE,
                mouseenter: FOCUSED,
            },
        },
        [FOCUSED]: {
            enter: startRestTimer,
            leave: clearRestTimer,
            on: {
                blur: IDLE,
                mousedown: DISMISSED,
                mouseleave: IDLE,
                mousemove: FOCUSED,
                rest: VISIBLE,
            },
        },
        [VISIBLE]: {
            on: {
                blur: LEAVING_VISIBLE,
                focus: FOCUSED,
                globalMouseMove: LEAVING_VISIBLE,
                mousedown: DISMISSED,
                mouseenter: FOCUSED,
                mouseleave: LEAVING_VISIBLE,
                selectWithKeyboard: DISMISSED,
            },
        },
        [LEAVING_VISIBLE]: {
            enter: startLeavingVisibleTimer,
            leave: () => {
                clearLeavingVisibleTimer();
                clearContextId();
            },
            on: {
                focus: VISIBLE,
                mouseenter: VISIBLE,
                timecomplete: IDLE,
            },
        },
        [DISMISSED]: {
            leave: () => {
                // allows us to come on back later w/o entering something else first
                context.id = null;
            },
            on: {
                blur: IDLE,
                mouseleave: IDLE,
            },
        },
    },
};

// chart context allows us to persist some data around, in Tooltip all we use
// is the id of the current tooltip being interacted with.
let context = { id: null };
let state = chart.initial;

////////////////////////////////////////////////////////////////////////////////
// Finds the next state from the current state + action. If the chart doesn't
// describe that transition, it will throw.
//
// It also manages lifecycles of the machine, (enter/leave hooks on the state
// chart)
function transition(action: any, newContext?: any) {
    const stateDef = chart.states[state];
    const nextState = stateDef.on[action];

    // Really useful for debugging
    // console.log({ action, state, nextState, contextId: context.id });

    if (!nextState) {
        throw new Error(`Unknown state for action "${action}" from state "${state}"`);
    }

    if (stateDef.leave) {
        stateDef.leave();
    }

    if (newContext) {
        context = newContext;
    }

    const nextDef = chart.states[nextState];
    if (nextDef.enter) {
        nextDef.enter();
    }

    state = nextState;
    notify();
}

////////////////////////////////////////////////////////////////////////////////
// Subscriptions:
//
// We could require apps to render a <TooltipProvider> around the app and use
// React context to notify Tooltips of changes to our state machine, instead
// we manage subscriptions ourselves and simplify the Tooltip API.
//
// Maybe if default context could take a hook (instead of just a static value)
// that was rendered at the root for us, that'd be cool! But it doesn't.
const subscriptions: Array<(state: any, context: any) => void> = [];

function subscribe(fn: (state: any, context: any) => void) {
    subscriptions.push(fn);
    return () => {
        subscriptions.splice(subscriptions.indexOf(fn), 1);
    };
}

function notify() {
    subscriptions.forEach((fn) => fn(state, context));
}

////////////////////////////////////////////////////////////////////////////////
// Timeouts:

// Manages when the user "rests" on an element. Keeps the interface from being
// flashing tooltips all the time as the user moves the mouse around the screen.
let restTimeout: number;

function startRestTimer() {
    clearTimeout(restTimeout);
    restTimeout = window.setTimeout(() => transition('rest'), 100);
}

function clearRestTimer() {
    clearTimeout(restTimeout);
}

// Manages the delay to hide the tooltip after rest leaves.
let leavingVisibleTimer: number;

function startLeavingVisibleTimer() {
    clearTimeout(leavingVisibleTimer);
    leavingVisibleTimer = window.setTimeout(() => transition('timecomplete'), 500);
}

function clearLeavingVisibleTimer() {
    clearTimeout(leavingVisibleTimer);
}

// allows us to come on back later w/o entering something else first after the
// user leaves or dismisses
function clearContextId() {
    context.id = null;
}

/**
 * This is a copy paste from `@reach/tooltip` so that we can use the customized `useRect`.
 * @private
 */
export function useTooltip({ onMouseEnter, onMouseMove, onMouseLeave, onFocus, onBlur, onKeyDown, onMouseDown, ref, DEBUG_STYLE }: any = {}) {
    const id = `tooltip:${useId()}`;
    const [isVisible, setIsVisible] = useState(DEBUG_STYLE ? true : context.id === id && state === VISIBLE);

    // hopefully they always pass a ref if they ever pass one
    const defaultRef = useRef();
    const triggerRef = ref || defaultRef;
    const triggerRect = useRect(triggerRef, isVisible);

    useEffect(() => {
        return subscribe(() => {
            if (context.id === id && (state === VISIBLE || state === LEAVING_VISIBLE)) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, [id]);

    const handleMouseEnter = () => {
        switch (state) {
            case IDLE:
            case VISIBLE:
            case LEAVING_VISIBLE: {
                transition('mouseenter', { id });
            }
        }
    };

    const handleMouseMove = () => {
        switch (state) {
            case FOCUSED: {
                transition('mousemove', { id });
            }
        }
    };

    const handleFocus = () => {
        switch (state) {
            case IDLE:
            case VISIBLE:
            case LEAVING_VISIBLE: {
                transition('focus', { id });
            }
        }
    };

    const handleMouseLeave = () => {
        switch (state) {
            case FOCUSED:
            case VISIBLE:
            case DISMISSED: {
                transition('mouseleave');
            }
        }
    };

    const handleBlur = () => {
        // Allow quick click from one tool to another
        if (context.id !== id) {
            return;
        }
        switch (state) {
            case FOCUSED:
            case VISIBLE:
            case DISMISSED: {
                transition('blur');
            }
        }
    };

    const handleMouseDown = () => {
        // Allow quick click from one tool to another
        if (context.id !== id) {
            return;
        }
        switch (state) {
            case FOCUSED:
            case VISIBLE: {
                transition('mousedown');
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            switch (state) {
                case VISIBLE: {
                    transition('selectWithKeyboard');
                }
            }
        }
    };

    const trigger = {
        'aria-describedby': id,
        'data-reach-tooltip-trigger': '',
        onBlur: composeEventHandlers(onBlur, handleBlur),
        onFocus: composeEventHandlers(onFocus, handleFocus),
        onKeyDown: composeEventHandlers(onKeyDown, handleKeyDown),
        onMouseDown: composeEventHandlers(onMouseDown, handleMouseDown),
        onMouseEnter: composeEventHandlers(onMouseEnter, handleMouseEnter),
        onMouseLeave: composeEventHandlers(onMouseLeave, handleMouseLeave),
        onMouseMove: composeEventHandlers(onMouseMove, handleMouseMove),
        ref: triggerRef,
    };

    const tooltip = {
        id,
        isVisible,
        triggerRect,
    };

    return [trigger, tooltip, isVisible] as const;
}
